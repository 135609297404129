export default defineNuxtRouteMiddleware(() => {
  const { $localePath } = useNuxtApp()

  const { authenticated } = useAuth()
  const { isMobile } = useDevice()

  if (authenticated.value) {
    if (isMobile) {
      return navigateTo(
        $localePath({
          name: 'myaccount-menu',
        }),
      )
    } else {
      return navigateTo(
        $localePath({
          name: 'myaccount-bookings',
        }),
      )
    }
  }
})
